import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import JobPost from "../views/JobPost.vue";
import PageNotFound from "../views/PageNotFound.vue";

import Login from "../views/Login.vue";
import ExternalLogin from "../views/ExternalLogin.vue";
import SetPassword from "../views/SetPassword.vue";
import ForgotPassword from "../views/ForgotPassword";
import Guest from "../views/Guest";

import PP from "../views/PP.vue";
import TnC from "../views/TnC.vue";
import TroopersProtectTnC from "../views/TroopersProtectTnC.vue";
import ChatTnC from "../views/ChatTnC.vue";
import ChatPP from "../views/ChatPP.vue";
import VTnC from "../views/VTnC.vue";
import faq from "../views/faq.vue";
import Troubleshoot from "../views/Troubleshoot.vue";

import util from "../lib/util";

import axios from "axios";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/login",
    name: "Login",
    component: Login,
    requiresGuest: true,
  },
  {
    path: "/external-login/:loginToken",
    name: "External Login",
    component: ExternalLogin,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/reset-password/:token",
    component: SetPassword,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/forgotPassword",
    component: ForgotPassword,
    meta: {
      requiresGuest: true,
    },
  },
  {
    path: "/terms-n-conditions",
    name: "tnc",
    component: TnC,
  },
  {
    path: "/troopers-protect-terms-n-conditions",
    name: "troopersProtectTnc",
    component: TroopersProtectTnC,
  },
  {
    path: "/troopers-chat/terms-n-conditions",
    name: "ChatTnc",
    component: ChatTnC,
  },
  {
    path: "/troopers-chat/privacy-policy",
    name: "ChatPP",
    component: ChatPP,
  },
  {
    path: "/various-terms-n-conditions",
    name: "vtnc",
    component: VTnC,
  },
  {
    path: "/privacy-policy",
    name: "pp",
    component: PP,
  },
  {
    path: "/faqs",
    name: "faq",
    component: faq,
  },
  {
    path: "/troubleshoot",
    name: "troubleshoot",
    component: Troubleshoot,
  },
  {
    path: "/discover/:jobPostID",
    name: "AppPage",
    component: JobPost,
  },
  {
    path: "/discover/search/:keyword",
    name: "Search Job Post",
    component: Home,
  },
  {
    path: "/referral",
    name: "referralPage",
    component: Home,
  },
  {
    path: "/my-jobs/:jobPostID",
    name: "myJobs",
    component: Home,
  },
  {
    path: "/troopers-protect",
    name: "troopersProtect",
    component: Home,
  },
  {
    path: "/guest",
    name: "guest",
    component: Guest,
  },
  {
    path: "/*",
    name: "PageNotFound",
    component: PageNotFound,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

let validateSessoin = () => {
  return new Promise((resolve) => {
    axios
      .get(`${util.methods.getRootAPI()}/api/v1/checkcookie`, {
        withCredentials: true,
      })
      .then((response) => {
        resolve(true);
      })
      .catch((error) => {
        resolve(false);
      });
  });
};

router.beforeEach(async (to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresGuest)) {
    const session = await validateSessoin();
    if (session) {
      next("/projects");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
