<template>
  <div class="bg-blue-100 text-orange-100 flex flex-col gap-8 p-8 h-full min-h-screen">
    <a :href="website" class="flex flex-col px-2">
      <img class="h-7" src="@/assets/header-logo-orange.svg" alt="Troopers Logo">
      <div class="text-md">
        <span>part-time & Manpower</span> <span class="font-bold"> Solutions Made Easy</span>
      </div>
    </a>

    <div class="bg-blue-80 p-4 rounded-xl flex flex-col md:flex-row gap-2 justify-between items-center max-w-4xl mx-auto flex-wrap">
      <img src="@/assets/download/download-now.png" alt="Download Troopers App Now" class="w-lg hidden md:block">
      <img src="@/assets/download/download-now-sm.png" alt="Download Troopers App Now" class="block md:hidden w-full">
      <div>
        <div class="flex gap-2">
          <a target="_blank" :href="`https://play.google.com/store/apps/details?id=my.com.troopers.parttime.android`">
            <img class="w-36" src="@/assets/android-button.png" alt="">
          </a>
          <a target="_blank" :href="`https://apps.apple.com/us/app/troopers-part-time-made-easy/id1541999583`">
            <img class="w-36" src="@/assets/ios-button.png" alt="">
          </a>
        </div>
        <div v-if="referralCode && referralSource" class="flex gap-2 text-white justify-center mt-2 text-lg">
          <div class="font-bold">Referral Code:</div>
          <div>
            {{referralSource}}-{{referralCode}}
          </div>
        </div>
      </div>
    </div>
    <img src="@/assets/download/why-troopers.png" alt="Why Troopers" class="mx-auto hidden md:block">
    <img src="@/assets/download/why-troopers-sm.png" alt="Why Troopers"  class="block md:hidden w-full">

    <div class="flex gap-4 flex-wrap w-full max-w-4xl mx-auto">
      <div v-for="(why, index) in whys" :key="index"
      class="flex flex-col gap-4 p-4 bg-blue-80 w-10 flex-1 rounded-xl" style="min-width: 8rem;">
        <img :src="require(`@/assets/download/icons/${why.icon}`)" class="h-10">
        <p class="text-white text-xs text-center">{{ why.body }}</p>
      </div>
    </div>
    <span to="/" class="text-white text-md">
      Visit <a :href="website" class="text-orange-100 font-bold underline">{{website}}</a> to know more about the app.
    </span>
  </div>
</template>

<script>
import {mapActions} from 'vuex'
export default {
  name: 'Home',
  components: {
    
  },
  computed:{
    referralCode(){
      return(this.$route?.query?.code)
    },
    referralSource(){
      return(this.$route?.query?.source)
    },
    website(){
      return process.env.VUE_APP_WEBSITE
    },
  },
  data() {
    return {
      whys: [
        {
          icon: "calendar.svg",
          body: "Choose to work on a flexible schedule",
        },
        {
          icon: "jobs.svg",
          body: "Discover over 100+ jobs near you",
        },
        {
          icon: "chat.svg",
          body: "Chat with your Supervisor conveniently and instantly",
        },
        {
          icon: "cash_out.svg",
          body: "Choose when you want to cash out your earnings",
        },
        {
          icon: "payment.svg",
          body: "Receive on-time payment and track your earnings easily",
        },
        {
          icon: "troopers_protect.svg",
          body: "Get insurance coverage of up to RM25,000.00 with Troopers Protect",
        },
      ]
    }
  },
  methods:{
    ...mapActions({
      getMyProfile: 'getMyProfile'
    })
  },
  async mounted(){
    let results = await this.getMyProfile()
    if(results.status==200){
      if(results.data.Type == 'supervisor'){
        window.location.href = '/supervisor'
      }
      else if(
        results.data.Type == 'admin'||
        results.data.Type == 'employer'||
        results.data.Type == 'recruiter'||
        results.data.Type == 'finance'
      ){
        if (this.$route.params.jobPostID) {
          window.location.href = `/cms/jobpost/${this.$route.params.jobPostID}` 
          return
        }
        window.location.href = '/cms'
      }
      else if(results.data.Type == 'guest'){
        window.location.href = '/guest'
      }
      else{
        window.location.href = '/partTimer'
      }
    }
  }
}
</script>
