import dayjs from "dayjs";
import "dayjs/locale/bg";

export default {
  methods: {
    getRootAPI() {
      if (window.location.host == process.env.VUE_APP_SECONDARY_WEB_DOMAIN) {
        return process.env.VUE_APP_SECONDARY_ROOT_API_URL;
      } else {
        return process.env.VUE_APP_ROOT_API_URL;
      }
    },
    customFormatDate: function (date, format) {
      try {
        return dayjs(date).format(format);
      } catch (e) {
        return date;
      }
    },
    formatDate: function (date) {
      try {
        return dayjs(date).format("DD-MMM-YYYY");
      } catch (e) {
        return date;
      }
    },
    formatCompactDate: function (date) {
      try {
        return dayjs(date).format("DD/MM/YY");
      } catch (e) {
        return date;
      }
    },
    formatISODate: function (date) {
      try {
        return dayjs(date).format("YYYY-MM-DD");
      } catch (e) {
        return date;
      }
    },
    formatDateTime: function (date) {
      try {
        return dayjs(date).format("DD-MMM-YYYY HH:mm");
      } catch (e) {
        return date;
      }
    },
    formatDateTime12H: function (date) {
      try {
        return dayjs(date).format("DD-MMM-YYYY hh:mm a");
      } catch (e) {
        return date;
      }
    },
    formatTime: function (time) {
      try {
        return dayjs("2020-01-01 " + time).format("hh:mm a");
      } catch (e) {
        return date;
      }
    },
    formatMonthYear: function (date) {
      try {
        return dayjs(date).format("MMM YYYY");
      } catch (e) {
        return date;
      }
    },
    calcDuration(time1, time2) {
      time1 = dayjs("2020-01-01 " + time1);
      time2 = dayjs("2020-01-01 " + time2);
      time1.isAfter(time2) ? (time2 = time2.add(1, "day")) : false;
      return time2.diff(time1, "hour");
    },
  },
};
