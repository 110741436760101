import Vue from "vue";
import Vuex from "vuex";
import Axios from "axios";
import util from "../lib/util";

const axios = Axios.create({
  withCredentials: true,
});

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoggedIn: false,
    myProfile: null,
    topMessageText: null,
    topMessageStatus: null,
    countries: [],
  },
  getters: {
    myProfile: (state) => state.myProfile,
    topMessageText: (state) => state.topMessageText,
    topMessageStatus: (state) => state.topMessageStatus,
    countries: (state) => state.countries,
    currentCountry: (state) =>
      state.countries.find((x) => process.env.VUE_APP_COUNTRY_CODE == x.countryAbbreviation),
  },
  mutations: {
    logIn(state, userProfile) {
      state.isLoggedIn = true;
      state.userProfile = userProfile;
    },
    changeTopMessage(state, value) {
      state.topMessageText = value.text;
      state.topMessageStatus = value.status;
    },
  },
  actions: {
    async login(context, loginData) {
      console.log("calling login");
      return new Promise((resolve, reject) => {
        axios
          .post(`${util.methods.getRootAPI()}/api/v1/Login`, {
            userIdentifier: loginData.email,
            password: loginData.password,
          })
          .then((response) => {
            if (response.status == 200) {
              window.location.href = response.data.location;
            } else {
              resolve(response);
            }
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    async logout(context) {
      axios
        .get(`${util.methods.getRootAPI()}/api/v1/logout`)
        .then((response) => {
          //successful logout
          Vue.set(context.state, "myProfile", null);
          context.commit("logOut");
          window.location.href = "/login";
        })
        .catch((error) => {
          router.push("/");
          console.log(error);
        });
    },
    async getMyProfile(context) {
      return new Promise((resolve) => {
        axios
          .get(`${util.methods.getRootAPI()}/api/v1/userProfile`, {
            withCredentials: true,
          })
          .then((response) => {
            Vue.set(context.state, "myProfile", response.data);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            resolve(error);
          });
      });
    },
    async requestResetToken(context, email) {
      return new Promise((resolve) => {
        axios
          .post(`${util.methods.getRootAPI()}/api/v1/requestResetToken`, {
            email: email,
          })
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            resolve(error);
          });
      });
    },
    async checkToken(context, token) {
      return new Promise((resolve) => {
        axios
          .get(`${util.methods.getRootAPI()}/api/v1/checkToken/${token}`)
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            resolve(error);
          });
      });
    },
    async setPassword(context, passwordData) {
      return new Promise((resolve) => {
        axios
          .post(`${util.methods.getRootAPI()}/api/v1/setPassword?token=${passwordData.token}`, {
            password: passwordData.password,
          })
          .then((response) => {
            resolve(response);
            // router.push("/login");
          })
          .catch((error) => {
            resolve(error);
            // router.push("/setPassword/invalid");
          });
      });
    },
    async getSysConfig(context, key) {
      return new Promise((resolve) => {
        axios
          .get(`${util.methods.getRootAPI()}/api/v1/systemConfig/${key}`)
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            resolve(error);
          });
      });
    },
    async getJobPost(context, jobPostID) {
      return new Promise((resolve) => {
        axios
          .get(`${util.methods.getRootAPI()}/api/v1/jobPost/${jobPostID}`)
          .then((response) => {
            console.log(response);
            resolve(response);
          })
          .catch((error) => {
            console.log(error);
            resolve(error);
          });
      });
    },

    async loginWithExternalToken(context, loginToken) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${util.methods.getRootAPI()}/api/v1/token-login/${loginToken}`)
          .then((response) => {
            window.location.href = response.data.location;
            resolve(response.data);
          })
          .catch((error) => {
            console.log(error);
            context.commit("changeTopMessage", {
              text: error.response.data.message,
              status: "error",
            });
            return reject(error);
          });
      });
    },
    async getCountries(context) {
      return new Promise((resolve) => {
        axios
          .get(`${util.methods.getRootAPI()}/api/v1/countries`)
          .then((response) => {
            Vue.set(context.state, "countries", response.data);
            resolve(response.data);
          })
          .catch((error) => {
            context.commit("changeTopMessage", {
              text: error.response.data.message,
              status: "error",
            });
            resolve(error);
          });
      });
    },
  },
  modules: {},
});
